

import React, { useState, useEffect, useContext } from 'react';
import { CloseSVG } from "../../assets/images";
import { Button, Input, Img, Heading } from "../../components";
import ProductDetails from "../../components/ProductDetails";
import Page2 from "../../modals/Page2";
import { CartContext } from '../../CartContext'; // Импортируем CartContext
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate

export default function MainMenuSection() {
  const [searchBarValue, setSearchBarValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Состояние для анимации
  const { addToCart } = useContext(CartContext);
  const { cartItems } = useContext(CartContext); 
  const [products, setProducts] = useState([]); // Состояние для продуктов
  const navigate = useNavigate(); // Инициализация useNavigate

    const handleLogoClick = () => {
      navigate('/cart'); // Перенаправление на главную страницу
    };
  // Статичные данные, которые будут добавлены к каждому продукту
  const defaultProductData = {
    productWeight: "",
    addToCartButton: "В КОРЗИНУ",
    viewDetailsButton: "ПОДРОБНЕЕ",
  };

  // Получение данных с сервера
  // useEffect(() => {
  //   fetch('https://souz-deserts.ru/api/products') // Замените URL на ваш
  //     .then(response => response.json())
  //     .then(data => {
  //       // Добавление статичных данных к каждому продукту
  //       const updatedProducts = data.map(product => ({
  //         ...product,
  //         ...defaultProductData,
  //         productImage: product.productImage || "images/default_image.png", // Добавьте стандартное изображение, если отсутствует
  //         productDescription: product.productDescription,
  //         productPrice: `${product.productPrice} р` // Форматирование цены
  //       }));
  //       setProducts(updatedProducts);
  //     })
  //     .catch(error => console.error('Ошибка при загрузке продуктов:', error));
  // }, []);
  useEffect(() => {
    fetch('https://souz-deserts.ru/api/products') // Замените URL на ваш
      .then(response => response.json())
      .then(data => {
        // Базовый URL для изображений через маршрут /images/:filename
        const imageBaseUrl = 'https://souz-deserts.ru/api/images/';
  
        // Добавление статичных данных к каждому продукту
        const updatedProducts = data.map(product => ({
          ...product,
          ...defaultProductData,
          productImage: product.productImage 
            ? `${imageBaseUrl}${product.productImage}` 
            : "images/default_image.png", // Добавьте стандартное изображение, если отсутствует
          productDescription: product.productDescription,
          productPrice: `${product.productPrice} р` // Форматирование цены
        }));
        setProducts(updatedProducts);
      })
      .catch(error => console.error('Ошибка при загрузке продуктов:', error));
  }, []);
  
  const handleViewDetails = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const handleAddToCart = (product) => {
    if (!isAnimating) {
      setIsAnimating(true); // Запуск анимации

      setTimeout(() => {
        addToCart(product);
        setIsAnimating(false); // Остановка анимации после добавления в корзину
      }, 1000); // Длительность анимации
    }
  };

  const filteredProducts = products.filter((product) =>
    product.productName.toLowerCase().includes(searchBarValue.toLowerCase())
  );

  return (
    <>
      <div className="flex-col items-center" >
        <div className="container-xs flex flex-col items-start md:p-5">
          <Heading as="h1">Меню</Heading>
          <div className="mt-[30px] flex gap-6 self-stretch md:flex-col">
            <Input
              name="Search Field"
              placeholder="Поиск"
              value={searchBarValue}
              onChange={(e) => setSearchBarValue(e.target.value)}
              prefix={<Img src="images/img_search.svg" alt="Search" className="h-[16px] w-[16px] cursor-pointer" />}
              suffix={
                searchBarValue?.length > 0 ? (
                  <CloseSVG onClick={() => setSearchBarValue("")} height={16} width={16} fillColor="#b42926ff" />
                ) : null
              }
              className="flex h-[48px] flex-grow items-center justify-center gap-4 rounded-[10px] bg-gray-200 pl-6 pr-[34px] font-gerbera text-[16px] text-red-900 sm:px-5"
            />
            {/* <Button className="flex h-[48px] min-w-[192px] flex-row items-center justify-center rounded-[10px] bg-red-900 px-[34px] text-center text-[16px] text-white-a700 sm:px-5">
              Найти
            </Button> */}
            <Button 
  className="hidden lg:flex h-[48px] min-w-[192px] flex-row items-center justify-center rounded-[10px] bg-red-900 px-[34px] text-center text-[16px] text-white-a700 sm:px-5"
>
  Найти
</Button>

          </div>
          <div className="mt-12 grid grid-cols-4 gap-6 gap-y-6 self-stretch md:grid-cols-2 sm:grid-cols-2">
            {filteredProducts.map((product, index) => (
              <ProductDetails 
                key={index} 
                {...product} 
                onViewDetails={() => handleViewDetails(product)} 
                onAddToCart={() => handleAddToCart(product)} 
                isAnimating={isAnimating} // Передача состояния анимации
              />
            ))}
          </div>
          {/* Кнопка, которая отображается только на маленьких экранах */}
          <div className="sm:block hidden mt-6">
            <Button 
              className="flex mx-auto sm:w-[330px] sm:h-[48px]  flex-row items-center justify-center rounded-[10px] sm:rounded-[10px] bg-red-900 px-[34px] items-center justify-center text-center text-[16px] text-white-a700"
              onClick={handleLogoClick}
            >
Посмотреть корзину            </Button>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Page2 isOpen={isModalOpen} onRequestClose={closeModal} product={selectedProduct} />
      )}
    </>
  );
}
