// import { Text, Img } from "./..";
// import React from "react";

// export default function Footer({ ...props }) {
//   return (
//     <footer
//       {...props}
//       className={`${props.className} flex justify-center items-end h-[366px] md:h-auto py-[70px] md:py-5 bg-red-900 bg-cover bg-no-repeat`}
//     >
//       <div className="container-xs mt-2 flex md:p-5">
//         <div className="flex w-[28%] flex-col gap-[50px] md:w-full">
//           <Img src="images/img_1_1.svg" alt="Footer Logo" className="h-[54px] w-[42%]" />
//           <div className="flex flex-col gap-2.5">
//             <div className="flex flex-col items-start">
//               <Text size="desktop_body" as="p">
//                 Телефон
//               </Text>
//               <Text size="desktop_body" as="p">
//               +7 (952) 679-60-22              </Text>
//             </div>
//             <div className="flex flex-col items-start">
//               <Text size="desktop_body" as="p">
//                 Адрес
//               </Text>
//               <Text size="desktop_body" as="p">
//               Город Томск, Яковлева, 15
//               </Text>
//             </div>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }
import { Text, Img } from "./..";
import React, { useState, useEffect } from "react";

export default function Footer({ ...props }) {
  const [footerLogoSrc, setFooterLogoSrc] = useState("images/img_1_1.svg");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [imageSize, setImageSize] = useState({ width: 400, height: 400 });

  useEffect(() => {
    // Функция для обновления src, определения экрана и вычисления размера изображения
    const updateLayout = () => {
      const width = window.innerWidth;

      if (width <= 640) {
        setFooterLogoSrc("images/footerSm.png");
        setIsSmallScreen(true);

        const newWidth = width - 20; // Ширина экрана минус 10 пикселей маржина с каждой стороны
        const newHeight = 400 - 32; // Высота изображения минус 32 пикселя маржина снизу

        setImageSize({ width: newWidth, height: newHeight });
      } else {
        setFooterLogoSrc("images/img_1_1.svg");
        setIsSmallScreen(false);
        setImageSize({ width: 400, height: 400 });
      }
    };

    // Вызываем функцию при монтировании компонента
    updateLayout();

    // Обновляем при изменении размера экрана
    window.addEventListener("resize", updateLayout);

    // Удаляем обработчик события при размонтировании компонента
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  return (
    <footer
  {...props}
  className={`${props.className} flex justify-center items-end h-[366px] md:h-auto py-[70px] md:py-5 bg-cover bg-no-repeat`}
  style={{ backgroundColor: window.innerWidth <= 640 ? 'white' : '#b42926' }}
>
      <div className="container-xs mt-2 flex md:p-5">
        <div className="flex w-[28%] flex-col gap-[50px] md:w-full">
          <Img 
            src={footerLogoSrc} 
            alt="Footer Logo" 
            className="object-contain sm:radius-[10px]" 
            style={{ width: isSmallScreen ? imageSize.width : "auto", height: isSmallScreen ? imageSize.height : "auto" }}
          />
          {!isSmallScreen && (
            <div className="flex flex-col gap-2.5">
              <div className="flex flex-col items-start">
                <Text size="desktop_body" as="p">
                  Телефон
                </Text>
                <Text size="desktop_body" as="p">
                  +7 (952) 679-60-22
                </Text>
              </div>
              <div className="flex flex-col items-start">
                <Text size="desktop_body" as="p">
                  Адрес
                </Text>
                <Text size="desktop_body" as="p">
                  Город Томск, Яковлева, 15
                </Text>
                <Text size="desktop_body" as="p">
                ИП Шишкина Ирина Валерьевна
                701747564954
                </Text>

              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}