import React from "react";

const NotFound = () => {
  return (
    <div>
      <h1>Opps! We ran out of code</h1>
    </div>
  );
};

export default NotFound;

